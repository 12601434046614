/* eslint-disable no-console */
let debug = true;
export function log(message, object) {
  if (debug) {
    if (object !== undefined) {
      console.log(message, object);
    } else {
      console.log(message);
    }
  }
}
export function logJson(name, object) {
  log(name + ": " + JSON.stringify(object, null, 2));
}
export function isToday(someDate) {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

export function decodeJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
