<template>
  <stockprice-card-list></stockprice-card-list>
</template>

<script>
// @ is an alias to /src
import StockpriceCardList from "@/components/StockpriceCardList.vue";

export default {
  name: "home",
  components: {
    StockpriceCardList,
  },
};
</script>
