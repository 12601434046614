<template>
  <div>
    <div class="container" v-if="false">
      Stocksbot Admin page:
      <div class="row justify-content-md-center">
        <div class="red col-md-auto">1 of 3</div>
        <div class="green col-md-auto">Variable width content</div>
        <div class="blue col-md-auto">3 of 3</div>
        <div class="yellow col-md-auto">3 of 3</div>
      </div>
      <div class="row">
        <div class="col red">1 of 3</div>
        <div class="col green">Variable width content</div>
        <div class="col blue">3 of 3</div>
      </div>
    </div>
    <wertpapier-admin></wertpapier-admin>
  </div>
</template>

<script>
// @ is an alias to /src
import WertpapierAdmin from "@/components/WertpapierAdmin.vue";

export default {
  name: "home",
  components: {
    WertpapierAdmin
  }
};
</script><style scoped></style>



