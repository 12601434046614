<template>
  <div v-if="authenticated">
    <v-app-bar clipped-left color="primary" app>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title class="title font-weight-light"
        >Stocks<span class="title">Dashboard</span>&nbsp;
        <sup class="caption">{{ appVersion }}</sup> ({{ nickname }})
        <v-icon v-if="authenticated">mdi-check</v-icon>
      </v-toolbar-title>
      <v-spacer />
      <v-btn text color="white" @click="handleLogout()">
        <span>Logout</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      mobile-breakpoint="0"
      color="grey lighten-4"
      mini-variant
      expand-on-hover
    >
      <v-list dense nav class="grey lighten-4">
        <template v-for="(item, index) in items">
          <v-row v-if="item.heading" :key="index" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn small text>edit</v-btn>
            </v-col>
          </v-row>
          <v-divider v-else-if="item.divider" :key="index+1" dark class="my-4" />
          <v-list-item
            v-else-if="item.route !== null && checkRoles(item.roles)"
            :key="index+2"
            :to="item.route"
          >
            <!-- // @click="drawer = false"  -->
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { log } from "@/services/utils";
import { user, admin, investor } from "@/utils/roles";
export default {
  name: "logout",
  data: () => ({
    drawer: null,
    items: [
      // { heading: "Labels" },
      {
        icon: "mdi-home",
        text: "Home",
        route: "/",
      },
      {
        icon: "mdi-chart-line",
        text: "Dashboard",
        route: "dashboard",
        roles: [user, investor],
      },
      {
        icon: "mdi-file-eye-outline",
        text: "Watchlist",
        route: "watchlist",
        roles: [user, investor],
      },
      {
        icon: "mdi-file-table-box-outline",
        text: "Admin",
        route: "admin",
        roles: [admin],
      },
      {
        icon: "mdi-account-multiple",
        text: "Users",
        route: "useradmin",
        roles: [admin],
      },
      {
        icon: "mdi-cash-multiple",
        text: "Wertpapiere",
        route: "wertpapieradmin",
        roles: [admin, investor],
      },
      { divider: true },
      // { icon: "mdi-cog-outline", text: "Settings", route: null }
      {
        icon: "mdi-cog-outline",
        text: "Settings",
        route: "settings",
      },
      // { icon: "mdi-logout", text: "Logout", route: "logout" }
    ],
  }),

  methods: {
    ...mapActions(["logout"]),

    handleLogout: function () {
      this.$store
        .dispatch("logout")
        .then((response) => {
          log(response);
          this.$router.push("/login"); // const requestData = {
        })
        .catch((e) => {
          log("Error while logging out " + e.message);
        });
    },
    checkRoles(roles) {
      log(roles);
      if (roles) {
        if (roles.includes(user) && this.isUser) {
          return true;
        }
        if (roles.includes(investor) && this.isInvestor) {
          return true;
        }
        if (roles.includes(admin) && this.isAdmin) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    ...mapGetters(["appVersion"]),
    ...mapState({
      nickname: (state) => state.user.nickname,
      authenticated: (state) => state.auth.authenticated,
      isUser: (state) => state.auth.hasRoleUser,
      isInvestor: (state) => state.auth.hasRoleInvestor,
      isAdmin: (state) => state.auth.hasRoleAdmin,
    }),
  },
};
</script>
