<template>
  <watchlist-editor></watchlist-editor>
</template>

<script>
// @ is an alias to /src
import WatchlistEditor from "@/components/WatchlistEditor2.vue";

export default {
  name: "watchlist",
  components: {
    WatchlistEditor,
  },
};
</script>
