<template>
  <div>
    <h1>Testpage for vuex state management</h1>
    <section>
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-12">
            <ul class="list-group">
              <li class="list-group-item">
                Firstname : {{ this.$store.state.user.firstname }}
              </li>
              <li class="list-group-item">Firstname : {{ firstname }}</li>
              <v-text-field
                label="Firstname"
                hide-details="auto"
                :value="firstname"
                v-model="firstnameInput"
              ></v-text-field>
              <input :value="firstname" @input="updateFirstname" />
              <li class="list-group-item">Fullname : {{ fullnameLocal }}</li>
              <li class="list-group-item">Fullname : {{ fullname }}</li>
              <li class="list-group-item">Lastname : {{ lastname }}</li>
              <li class="list-group-item">Nickname : {{ nickname }}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      nickname: (state) => state.user.nickname,
      firstname: (state) => state.user.firstname,

      // to access local state with `this`, a normal function must be used
      fullnameLocal(state) {
        return state.user.lastname + ", " + state.user.firstname;
      },
    }),
    // ...mapState([
    //   "firstname",
    //   // ...
    // ]),
    ...mapGetters([
      "fullname",
      // ...
    ]),
    // firstname: (state) => state.user.firstname,
    lastname() {
      return this.$store.state.user.lastname;
    },
    // Beispiel für eine gebundene vuex Property
    firstnameInput: {
      get() {
        return this.$store.state.user.firstname;
      },
      set(value) {
        this.$store.commit("setFirstname", value);
      },
    },
  },

  methods: {
    updateFirstname(e) {
      this.$store.commit("setFirstname", e.target.value);
    },
  },
  // created() {
  //   this.getUserDetails();
  // },
};
</script>
<style>
#navbar {
  margin-bottom: 15px;
}
</style>