/* eslint-disable no-console */
<template>
  <div>
    <fadeout-alert
      :show="alert.show"
      :type="alert.type"
      :timeout="alert.timeout"
      >{{ alert.dialogMessage }}</fadeout-alert
    >
    <div class="home">Stocksbot Homepage</div>
    <ul>
      <li>Admin: {{ isAdmin }}-{{ admin }}</li>
      <li>User: {{ isUser }}-{{ user }}</li>
      <li>Investor: {{ isInvestor }}-{{ investor }}</li>
      <li>is logged in: {{ isLoggedIn }}</li>
      <li>is authenticated: {{ isAuthenticated }}-{{ authenticated }}</li>
      <li>token: {{ decodedToken }}</li>
      <li>boolean: {{ isAdmin || isUser }}</li>
      <v-btn block @click="alert.show = !alert.show">Click me!</v-btn>
    </ul>
  </div>
</template>
<script>
import TokenService from "@/services/token.service";
import FadeoutAlert from "@/components/FadeoutAlert";
import { mapState } from "vuex";

export default {
  name: "home",
  components: {
    FadeoutAlert,
  },
  data() {
    return {
      dialog: false,
      dialogMessage: "",
      alert: {
        show: false,
        dialogMessage: "",
        type: "success",
        timeout: 3000,
      },
    };
  },
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      admin: (state) => state.auth.hasRoleAdmin,
      user: (state) => state.auth.hasRoleUser,
      investor: (state) => state.auth.hasRoleInvestor,
      authenticated: (state) => state.auth.authenticated,

      // to access local state with `this`, a normal function must be used
      fullnameLocal(state) {
        return state.user.lastname + ", " + state.user.firstname;
      },
    }),

    isAdmin() {
      return TokenService.hasAdminRole();
    },
    isUser() {
      return TokenService.hasUserRole();
    },
    isInvestor() {
      return TokenService.hasInvestorRole();
    },
    isLoggedIn() {
      return TokenService.isLoggedIn();
    },
    isAuthenticated() {
      return this.$store.state.auth.authenticated;
    },
    decodedToken() {
      return TokenService.decodedToken();
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    console.log("hallo");
    this.alert.dialogMessage =
      "Some message to the user<p>Some message to the user<p>Some message to the user<p>Some message to the user<p>";
    this.alert.showt = !this.alert.show;
  },
};
</script>
