<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on: dialogProps, attrs }">
      <!-- Open Dialog -->
      <v-tooltip bottom open-delay="500" >
        <template v-if="priceTargetAvailable" #activator="{ on: tooltip }">
          <v-btn
            icon
            x-small
            @click="dialogOpen"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialogProps  }"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span>Kursziel ändern</span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-if="!priceTargetAvailable" #activator="{ on: tooltip }">
          <v-btn
            icon
            x-small
            @click="dialogOpen"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialogProps }"
          >
            <v-icon small>mdi-bullseye-arrow</v-icon>
          </v-btn>
        </template>
        <span>Kursziel erstellen</span>
      </v-tooltip>
    </template>
    <v-card>
      <fadeout-alert :show="alert.show" :type="alert.type" :timeout="3000">
        <div v-for="item in alert.messages" :key="item">
          {{ item }}
        </div>
      </fadeout-alert>
      <v-card-title>
        <span class="headline">Zielkurs für {{ editedPriceTarget.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Zielkurs"
                hint="Zielkurs in EUR"
                v-model="editedPriceTarget.price"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="['BUY', 'SELL']"
                v-model="editedPriceTarget.action"
                label="Aktion"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="10">
              <v-textarea
                outlined
                v-model="editedPriceTarget.notes"
                rows="3"
                label="Notizen"
                hint="Ihre Notizen zum Kauf oder Verkauf"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                v-model="editedPriceTarget.private"
                label="private"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small>* Pflichtfeld</small>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Abbrechen </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="deleteTarget"
          v-if="priceTargetAvailable"
        >
          Löschen
        </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Speichern </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as format from "@/services/formatter.js";
import { log, logJson } from "../services/utils";
import ApiService from "@/services/api.service";
import FadeoutAlert from "@/components/FadeoutAlert";

export default {
  name: "TargetPriceDialog",
  actions: ["BUY", "SELL"],
  components: {
    FadeoutAlert,
  },
  props: {
    priceTarget: {
      name: { required: true, type: String },
      isin: { required: true, type: String },
      price: { required: true, type: Number },
      action: { required: true, type: String },
      private: { required: false, type: Boolean },
      notes: { required: true, type: String },
    },
    priceTargetAvailable: { required: false, type: Boolean },
  },
  filters: {
    currency: function (value) {
      return format.toCurrency(value);
    },
    percent: function (value) {
      return format.toPercent(value);
    },
  },

  data() {
    return {
      dialog: false,
      checkbox: true,
      myPrice: 100,
      editedPriceTarget: {
        price: 0,
        isin: "",
        name: "",
        action: "",
        private: false,
        notes: "",
      },
      defaultItem: {
        price: -1,
        isin: "",
        name: "Wertpapiername",
        action: "",
        private: false,
        notes: "Deine Notizen zum Kursziel",
      },
      alert: {
        show: false,
        messages: [],
        type: "success",
      },
    };
  },
  methods: {
    showAlert(messages, type) {
      this.alert.messages = messages;
      this.alert.type = type;
      this.alert.show = !this.alert.show;
    },
    initialize() {
      log("Initializing");
      // this.refreshFromServer();
      this.editedPriceTarget = Object.assign({}, this.priceTarget);
      this.log();
    },
    close() {
      log("Closing dialog without saving");
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    log() {
      log(JSON.parse(JSON.stringify(this.priceTarget)));
      log(JSON.parse(JSON.stringify(this.editedPriceTarget)));
      log(this.myPrice);
      this.dialog = false;
    },
    dialogOpen() {
      log("dialog opened");
      this.initialize();
    },
    save() {
      log("saving pricetarget");
      log(this.editedPriceTarget);
      const requestData = {
        method: "post",
        url: "/api/stocks/dashboard/pricetarget/save",
        data: this.editedPriceTarget,
      };
      ApiService.customRequest(requestData)
        .then((response) => {
          log(response);
          Object.assign(this.priceTarget, response.data);
          logJson("response ", response.data);
          logJson("priceTarget ", this.priceTarget);
          this.$emit("target-saved");
          this.dialog = false;
        })
        .catch((e) => {
          const message = "Error while saving price target " + e.response.data;
          log(message);
          this.showAlert([message], "error");
        })
        .finally(() => {});
    },
    deleteTarget() {
      log("deleting priceTarget");
      const requestData = {
        method: "post",
        url: "/api/stocks/dashboard/pricetarget/delete",
        data: this.editedPriceTarget,
      };
      ApiService.customRequest(requestData)
        .then((response) => {
          log(response);
          Object.assign(this.editedPriceTarget, this.defaultItem);
        })
        .finally(() => {
          this.dialog = false;
          this.$emit("target-deleted");
        });
    },
  },
};
</script>

<style></style>
