/* eslint-disable no-debugger */
// Taken from https://www.youtube.com/watch?v=P5YWkLsA9JQ&list=PLfdtiltiRHWF1jqLcNO_2jWJXj9RuSDvY&index=8
// Adjust module/action setup according to https://medium.com/dailyjs/mastering-vuex-zero-to-hero-e0ca1f421d45
import ApiService from "@/services/api.service";
import { log } from "@/services/utils.js";
import TokenService from "@/services/token.service";
// import { AUTH_SET_TOKEN, AUTH_LOGOUT, AUTH_LOGIN } from "../actions";
// initial state

const state = () => ({
  authenticated: false,
  hasRoleUser: false,
  hasRoleInvestor: false,
  hasRoleAdmin: false,
});

// getters
const getters = {};

// mutations
const mutations = {
  setAuthenticated(state, authenticated) {
    log("setting authenticated to " + authenticated);
    state.authenticated = authenticated;
  },
  setRoleUser(state, hasRole) {
    log("setting role user to " + hasRole);
    state.hasRoleUser = hasRole;
  },
  setRoleInvestor(state, hasRole) {
    log("setting role investor to " + hasRole);
    state.hasRoleInvestor = hasRole;
  },
  setRoleAdmin(state, hasRole) {
    log("setting role admin to " + hasRole);
    state.hasRoleAdmin = hasRole;
  },
};

// actions
const actions = {
  // try to login user with credentials (username, password)
  login({ commit, dispatch }, credentials) {
    log("in login");
    log(credentials);
    const requestData = {
      method: "post",
      url: "/api/auth/login",
      data: credentials,
    };
    log(requestData);
    return ApiService.customRequest(requestData)
      .then((response) => {
        if (response.status === 200) {
          commit("setAuthenticated", true);
          const token = response.data;
          log(token);
          TokenService.saveToken(token);
          // Setting roles
          dispatch("initRolesAndUser");
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
      })
      .catch((e) => {
        const message = "Error while authenticating with server " + e.message;
        log(message);
        throw new Error(message);
      });
  },

  // Check if we have a valid token and if yes init the user
  checkRefreshToken({ commit, dispatch }) {
    log("in checkRefreshToken");
    return ApiService.post("/api/auth/login", {})
      .then((response) => {
        if (response.status === 200) {
          commit("setAuthenticated", true);
          const token = response.data;
          log(token);
          TokenService.saveToken(token);
          // Setting roles
          dispatch("initRolesAndUser");
          return response;
        } else if (response.status === 401) {
          return response;
        }
        log("Weder 200 noch 401, dieser Fall muss näher untersucht werden");
        return response;
      })
      .catch((e) => {
        const message =
          "Unexpected error while authenticating with server " + e.message;
        log(message);
        throw new Error(message);
      });
  },

  // Initialize Roles and User data from and existing token.
  initRolesAndUser({ commit }) {
    commit("setAuthenticated", true);
    commit("setRoleUser", TokenService.hasUserRole());
    commit("setRoleInvestor", TokenService.hasInvestorRole());
    commit("setRoleAdmin", TokenService.hasAdminRole());
    const jwt = TokenService.decodedToken();
    log(jwt);
    commit("setNickname", jwt.sub);
    commit("setFirstname", "Thomas");
    commit("setLastname", "Bolz");
  },

  // Returns a Promise that handles the logout stuff
  logout({ commit, dispatch }) {
    return ApiService.get("/api/auth/logout")
      .then((response) => {
        log("in logout");
        commit("setAuthenticated", false);
        commit("setRoleUser", false);
        commit("setRoleInvestor", false);
        commit("setRoleAdmin", false);
        TokenService.cleanup();
        // ApiService.removeHeader();
        dispatch("cleanupUser");
        return response;
      })
      .catch((e) => {
        const message = "Error while authenticating with server " + e.message;
        log(message);
        throw new Error(message);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
