<template >
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Stocksbot Login</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Login"
                :name="form.username"
                v-model="form.username"
                prepend-icon="mdi-account"
                type="text"
                v-on:keyup.enter="submit()"
              />

              <v-text-field
                id="password"
                label="Password"
                :name="form.password"
                v-model="form.password"
                prepend-icon="mdi-lock"
                type="password"
                v-on:keyup.enter="submit()"
              />
              <v-card-actions>
                <span class="error--text" v-if="errorMessage"
                  ><v-icon color="error">mdi-alert-circle-outline</v-icon>
                  {{ errorMessage }}</span
                >
                <!-- <v-alert
                  dense
                  outlined
                  type="error"
                  dismissible
                  v-if="errorMessage"
                  >{{ errorMessage }}</v-alert
                > -->
                <v-spacer />
                <v-btn color="primary" @click="submit()"
                  >Login {{ authenticated }}</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { log } from "@/services/utils.js";

export default {
  name: "login",

  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      errorMessage: null,
    };
  },

  methods: {
    ...mapActions({ login: "auth/login" }),
    submit() {
      this.errorMessage = null;
      const self = this;
      this.$store
        .dispatch("login", this.form)
        // this.login(this.form)
        .then(function (response) {
          log("result from promise");
          log(response);
          self.errorMessage = null;
          // self.$store.dispatch("initWithToken", result);
          log("Hier kommen wir nie an");
          self.$router.push({ name: "home" });
        })
        // Im Moment lesen wir den HTTP Error Code noch aus dem Error Objekt aus, da die Login-Methode keine HTTP Response zurückgibt, das sollte
        // aber geändert werden und dann das Error-Handling im then() Bereich und abhängig vom response.status-Wert gemacht werden.
        .catch((error) => {
          if (error.message.includes("500")) {
            this.errorMessage = "Technical error during login";
          } else if (error.message.includes("404")) {
            this.errorMessage = "Credentials not valid";
          } else {
            this.errorMessage = "Unknown error";
          }
          log("Technical error during login");
          log(error);
        });
    },
  },
  computed: {
    ...mapState({
      nickname: (state) => state.user.nickname,
      authenticated: (state) => state.auth.authenticated,
      isUser: (state) => state.auth.hasRoleUser,
      isInvestor: (state) => state.auth.hasRoleInvestor,
      isAdmin: (state) => state.auth.hasRoleAdmin,
    }),
  },
};
</script>
