// Best practices: https://slides.com/thorstenlunborg/vuex-best-practices#/7
// Good example: https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart
import Vue from "vue";
import Vuex from "vuex";
// import user from "./modules/user";
// import auth from "./modules/auth";
import auth from "./modules/auth";
import user from "./modules/user";

Vue.use(Vuex);

const strict = process.env.NODE_ENV !== "production";

// export const store = new Vuex.Store({
export default new Vuex.Store({
  state: {
    packageVersion: "v0.35",
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
  },
  mutations: {},
  actions: {},
  modules: { auth, user },
  strict: strict,
  // vuex im sessionStorage speichern (ohne Argument wird's im localStorage gespeichert)
  // plugins: [createPersistedState({ storage: window.sessionStorage })],
});
