<!-- Container component for StockpriceCards on the WatchlistView -->
<template>
  <v-container fluid px-2 py-0>
    <fadeout-alert :show="alert.show" :type="alert.type" :timeout="3000">
      <div v-for="item in alert.messages" :key="item">
        {{ item }}
      </div>
    </fadeout-alert>
    <v-row dense>
      <v-col cols="6" sm="3">
        <v-select
          :disabled="loading"
          :items="sortOptions"
          label="Sort by"
          item-value="shortCode"
          item-text="text"
          v-model="selectedColumn"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6" sm="5">
        <v-text-field
          v-model="searchTerm"
          label="Search"
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="2">
        <v-btn
          outlined
          block
          @click="changeSortOrder"
          height="56"
          :disabled="loading"
          ><v-icon> {{ sortDirectionIcon }} </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" sm="2">
        <v-btn
          outlined
          block
          @click="updatePrices"
          height="56"
          :disabled="updating || loading"
        >
          <v-icon v-bind:class="{ customLoader: updating }">mdi-cached</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col v-if="loading" cols="6">
        <v-progress-linear indeterminate rounded height="10"></v-progress-linear
      ></v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row dense id="stockprices">
      <v-col
        cols="12"
        sm="6"
        lg="4"
        xl="3"
        v-for="stock in filteredStockModels"
        v-bind:key="stock.isin"
      >
        <stockprice-card :stock="stock"></stockprice-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";
import StockpriceCard from "@/components/StockpriceCard.vue";
import FadeoutAlert from "@/components/FadeoutAlert";
import { log } from "@/services/utils.js";

export default {
  name: "StockpriceCardList",
  data: function () {
    return {
      stockModels: [],
      selectedColumn: "", // selected sort field in Sort by combobox
      sortDirection: "",
      loading: true,
      toggle_exclusive: 1,
      sortDirectionIcon: "mdi-arrow-down",
      sortOptions: [
        { shortCode: "price", text: "current price" },
        { shortCode: "change", text: "change to previous day in %" },
        { shortCode: "changeToMax", text: "change to alltime high in %" },
        { shortCode: "date", text: "date" },
        { shortCode: "priceTarget", text: "distance to price target" },
      ],
      updating: false,
      alert: {
        show: false,
        messages: [],
        type: "success",
      },
      searchTerm: "",
    };
  },
  components: {
    StockpriceCard,
    FadeoutAlert,
  },
  watch: {
    // If the sort order changes we adjust the direction icon that is used in the v-select
    sortDirection: function () {
      this.sortDirectionIcon =
        this.sortDirection === "desc" ? "mdi-arrow-down" : "mdi-arrow-up";
    },
    selectedColumn: function () {
      log("in watcher for selectedColumn");
      this.changeSortColumn();
    },
  },
  methods: {
    changeSortOrder() {
      this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
      this.sortStockpricesBy(this.selectedColumn);
    },
    changeSortColumn() {
      log("in changeSortColumn");
      let stockCard;
      for (stockCard of this.stockModels) {
        stockCard.sortColumn = this.selectedColumn;
      }
      this.sortStockpricesBy(this.selectedColumn);
    },
    refreshWatchlist() {
      this.selectedColumn = "change";
      this.sortDirection = "desc";
      ApiService.get("/api/stocks/watchlist/isins")
        .then((response) => {
          log(response.data);
          let isin;
          // Enrich StockModel data with sortColumn field
          for (isin of response.data) {
            log(isin);
            const resource = "/api/stocks/dashboard/stockmodel/" + isin;
            ApiService.get(resource)
              .then((response) => {
                let stockModel;
                stockModel = response.data;
                stockModel.sortColumn = "change";
                this.stockModels.push(stockModel);
                this.sortStockpricesBy(this.selectedColumn);
              })
              .finally(() => {});
          }
        })
        .catch((e) => {
          log("something went terribly wrong");
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // not used anymore
    refreshStockprices() {
      ApiService.get("/api/stocks/dashboard")
        .then((response) => {
          let element;
          // Enrich StockModel data with sortColumn field
          for (element of response.data) {
            element.sortColumn = "";
          }
          this.stockModels = response.data;
          // default sorting field und order nach dem refresh()
          this.selectedColumn = "change";
          this.sortDirection = "desc";
          this.loading = false;
        })
        .catch((e) => {
          this.alert.messages = [];
          this.alert.messages.push(
            "Something went wrong while getting Dashboard data:"
          );
          e.response.data.forEach((wertpapier) => {
            this.alert.messages.push(wertpapier);
          });
          log(this.alert.messages);
          this.alert.type = "error";
          this.alert.show = !this.alert.show;
          this.loading = false;
        });
    },
    sortStockpricesBy(field) {
      log("Sortierung" + field);
      switch (field) {
        case "priceTarget":
          if (this.sortDirection === "asc") {
            this.stockModels.sort(
              (a, b) => a.percentToPriceTarget - b.percentToPriceTarget
            );
            log("stockprices sorted by priceTarget ascending");
          } else {
            this.stockModels.sort(
              (a, b) => b.percentToPriceTarget - a.percentToPriceTarget
            );
            log("stockprices sorted by priceTarget descending");
          }
          break;
        case "price":
          if (this.sortDirection === "asc") {
            this.stockModels.sort((a, b) => a.price - b.price);
            log("stockprices sorted by price ascending");
          } else {
            this.stockModels.sort((a, b) => b.price - a.price);
            log("stockprices sorted by price descending");
          }
          break;
        case "changeToMax":
          if (this.sortDirection === "asc") {
            this.stockModels.sort(
              (a, b) => a.changeToMaxInPercent - b.changeToMaxInPercent
            );
            log("stockprices sorted by changeToMaxInPercent ascending");
          } else {
            this.stockModels.sort(
              (a, b) => b.changeToMaxInPercent - a.changeToMaxInPercent
            );
            log("stockprices sorted by changeToMaxInPercent descending");
          }
          break;
        case "date":
          if (this.sortDirection === "asc") {
            this.stockModels.sort((a, b) => a.date - b.date);
            log("stockprices sorted by date ascending");
          } else {
            this.stockModels.sort((a, b) => b.date - a.date);
            log("stockprices sorted by date descending");
          }
          break;
        case "change":
        default:
          if (this.sortDirection === "asc") {
            this.stockModels.sort(
              (a, b) => a.changeInPercent - b.changeInPercent
            );
            log("stockprices sorted by changeInPercent ascending");
          } else {
            this.stockModels.sort(
              (a, b) => b.changeInPercent - a.changeInPercent
            );
            log("stockprices sorted by changeInPercent descending");
          }
          break;
      }
      log("sorting");
    },
    updatePrices() {
      this.updating = true;
      log("updating all cards");
      log(this.stockModels);
      const updatePromises = [];
      for (const stockModel of this.stockModels) {
        // log(stockModel.isin);
        const path = "/api/stocks/dashboard/update/" + stockModel.isin;
        // log(path);
        const stockmodelUpdateRequest = {
          method: "get",
          url: path,
        };
        let updatePromise = ApiService.customRequest(stockmodelUpdateRequest)
          .then((response) => {
            log("Single promise executed for " + stockModel.name);
            log(response);
            var json = response.data;
            stockModel.price = json.price;
            stockModel.date = json.date;
            stockModel.changeInPercent = json.changeInPercent;
            stockModel.changeInCurrency = json.changeInCurrency;
            stockModel.changeToMaxInPercent = json.changeToMaxInPercent;
            stockModel.changeToMinInPercent = json.changeToMinInPercent;
            return stockModel.name + " updated ok";
          })
          .catch((e) => {
            const errorMessage =
              "Error while updating stockprice for " +
              stockModel.name +
              "(" +
              e.message +
              ")";
            log(errorMessage);
            throw new Error(errorMessage);
            // return errorMessage;
          });
        updatePromises.push(updatePromise);
      }
      log("Before promises are executed");
      Promise.all(updatePromises)
        .then((values) => {
          log("After promises have been executed");
          log("return values of Promise.all ");
          log(values);
          this.alert.messages = [];
          values.forEach((value) => {
            this.alert.messages.push(value);
          });
          log(this.alert.messages);
          // this.alert.dialogMessage = values;
          this.alert.type = "success";
          this.alert.show = !this.alert.show;
        })
        .catch((e) => {
          const message =
            "Error while updating all stockprices in dashboard " + e.message;
          log(message);
          this.alert.messages = [message];
          this.alert.type = "error";
          this.alert.show = !this.alert.show;
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
  computed: {
    filteredStockModels: function () {
      if (!this.searchTerm) {
        return this.stockModels;
      } else {
        return this.stockModels.filter((stockModel) =>
          stockModel.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
    },
  },
  created() {
    this.refreshWatchlist();
    this.dialogMessage =
      "Some message to the user<p>Some message to the user<p>Some message to the user<p>Some message to the user<p>";
    this.dialog = !this.dialog;
  },
};
</script>

<style></style>
