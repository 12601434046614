/* eslint-disable no-debugger */
/* eslint-disable no-console */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Admin from "@/views/Admin.vue";
import WertpapierAdmin from "@/views/WertpapierAdminView.vue";
import UserAdmin from "@/views/UserAdminView.vue";
import Dashboard from "@/views/Dashboard.vue";
import WatchlistView from "@/views/WatchlistView.vue";
import Settings from "@/views/Settings.vue";
import Login from "@/views/Login.vue";
import { log } from "@/services/utils";
import { user, investor, admin } from "@/utils/roles";
import store from "@/store";

// Zentrale Funktion zur Prüfung von Rollen auf Pfaden.
// Sofern ein Pfad auf eine oder mehrere Rollen eingeschränkt ist muss das über das Meta-Objekt
// in der Route definiert werden:
//
// meta: { roles: [user, investor, admin] },

const checkRole = async (to, from, next) => {
  log(to + from);
  log("in checkRole()");
  log("to.meta.roles", to.meta.roles);
  log("from.meta.roles", from.meta.roles);
  log("authenticated:", store.state.auth.authenticated);

  // Falls wir nicht authentifiziert sind, prüfen wir, ob wir ein gültiges refreshToken haben und uns damit einloggen können
  if (!store.state.auth.authenticated) {
    log("Wir sind nicht eingeloggt und versuchen es über das refreskToken");
    await store
      .dispatch("checkRefreshToken")
      .then()
      .catch((e) => {
        const message = "Error while checking refresh token " + e.message;
        log(message);
        return next("/error");
      });
  }
  // Prüfen, ob wir nach dem refreshToken check eingeloggt sind
  if (store.state.auth.authenticated) {
    // Falls ja, ab hier prüfen wir, ob eine Rolle konfiguriert ist für die route und ob wir diese Rolle haben
    if (to.meta.roles.includes(user) && store.state.auth.hasRoleUser) {
      log("Rolle user check");
      return next();
    }
    if (to.meta.roles.includes(investor) && store.state.auth.hasRoleInvestor) {
      log("Rolle investor check");
      return next();
    }
    if (to.meta.roles.includes(admin) && store.state.auth.hasRoleAdmin) {
      log("Rolle admin check");
      return next();
    }
    // Falls wir eingeloggt sind, aber kein meta-Objekt haben, ist die Route für alle Rollen offen
    if (!to.meta.roles) {
      log(
        "Wir sind eingeloggt und es gibt keine Rollen-Einschränkungen auf die Seite"
      );
      return next();
    }
  } else {
    return next("/login");
  }
  log("Keine Rolle vorhanden (nicht eingeloggt) oder keine Rolle gesetzt");
  return next(false);
};

// Wird nur für die /login route abgeprüft
// Falls wir eingeloggt sind, Umleitung auf die Homepage, ansonsten auf die Login-Seite
const checkAuthenticated = (to, from, next) => {
  log("in checkAuthenticated()");
  log("authenticated=", store.state.auth.authenticated);
  if (store.state.auth.authenticated) {
    return next("/");
  } else {
    return next();
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: { roles: [user, investor, admin] },
    component: Home,
    beforeEnter: checkRole,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: { roles: [user, investor, admin] },
    beforeEnter: checkRole,
  },
  {
    path: "/wertpapieradmin",
    name: "wertpapieradmin",
    component: WertpapierAdmin,
    meta: { roles: [investor, admin] },
    beforeEnter: checkRole,
  },
  {
    path: "/useradmin",
    name: "useradmin",
    component: UserAdmin,
    meta: { roles: [admin] },
    beforeEnter: checkRole,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { roles: [user, investor] },
    beforeEnter: checkRole,
  },
  {
    path: "/watchlist",
    name: "watchlist",
    component: WatchlistView,
    meta: { roles: [user, investor] },
    beforeEnter: checkRole,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: checkAuthenticated,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    beforeEnter: checkRole,
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/Error.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
