/* eslint-disable no-console */
// Number Formatting
let number = 123456.7891;
let percent = 0.045678;
let date = new Date();
let logging = false;

function log(message) {
  if (logging) {
    console.log(message);
  }
}
log("" + number + percent + date);

let currencyFormat = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR"
});
export function toCurrency(number) {
  if (!number) return "";
  return currencyFormat.format(number);
}
log(`Currency:\t${number} => ${toCurrency(number)}`);

let amountFormat = new Intl.NumberFormat('de-DE', { 
  minimumFractionDigits: 2, 
  maximumFractionDigits: 2 
});
export function toAmount(number) {
  if (!number) return "";
  return amountFormat.format(number);
}

let percentFormat = new Intl.NumberFormat("de-DE", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
export function toPercent(number) {
  if (!number) return "";
  return percentFormat.format(number);
}
log(`Percent:\t${percent} => ${toPercent(percent)}`);

let dateFormatShort = new Intl.DateTimeFormat("de-DE");
export function toDate(date) {
  if (!date) return "";
  return dateFormatShort.format(date);
}
log(`Date:\t${date} => ${toDate(date)}`);

// Langer Wochentag mit langem Datum
const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
};
let dateFormatLong = new Intl.DateTimeFormat("de-DE", options);
export function toDateTime(date) {
  if (!date) return "";
  return dateFormatLong.format(date);
}
log(`Date:\t${date} => ${toDateTime(date)}`);
