// initial state
const state = () => ({
  firstname: "",
  lastname: "",
  nickname: "",
});

// getters
const getters = {
  fullname: (state) => {
    return state.firstname + " " + state.lastname;
  },
};

// actions
const actions = {
  // Aufräumen
  cleanupUser({ commit }) {
    commit("setFirstname", null);
    commit("setLastname", null);
    commit("setNickname", null);
  },
};

// mutations
const mutations = {
  setFirstname(state, firstname) {
    state.firstname = firstname;
  },
  setLastname(state, lastname) {
    state.lastname = lastname;
  },
  setNickname(state, nickname) {
    state.nickname = nickname;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
