<!-- Component to display a single stock on the watchlist -->
<template>
  <v-card class="mx-auto" outlined :color="computedColor">
    <v-card-title
      v-text="stock.name"
      class="text-h6 mt-n2 mb-n4"
    ></v-card-title>
    <v-card-text class="mt-n2 my-n5">
      <table style="width: 100%">
        <tr>
          <td class="text-right">Prev. day:</td>
          <td class="px-1 text-right grey lighten-1">
            {{ stock.changeInPercent | percent }}
          </td>
          <td class="text-right">Delta:</td>
          <td class="px-1 text-right grey lighten-1">
            {{ stock.changeInCurrency | currency }}
          </td>
        </tr>
        <tr>
          <td class="text-right">Kurs:</td>
          <td class="px-1 text-right grey lighten-1">
            {{ stock.price | currency }}
          </td>
          <td class="text-right">to Max:</td>
          <td class="px-1 text-right grey lighten-1">
            {{ stock.changeToMaxInPercent | percent }}
          </td>
        </tr>
        <!-- Zielkurs: Zielkursinfos anzeigen falls vorhanden ...  -->
        <tr v-if="priceTargetAvailable">
          <td class="text-right">
            Kursziel
            <v-icon small v-if="stock.priceTarget.action == 'SELL'">
              mdi-alpha-v-circle-outline
            </v-icon>
            <v-icon small v-if="stock.priceTarget.action == 'BUY'">
              mdi-alpha-k-circle-outline
            </v-icon>
            <target-price-dialog
              :priceTarget="priceTarget"
              :priceTargetAvailable="priceTargetAvailable"
              v-on:target-saved="targetSaved"
              v-on:target-deleted="targetDeleted"
            ></target-price-dialog>
          </td>
          <td class="px-1 text-right grey lighten-1">
            {{ stock.priceTarget.price | currency }}
          </td>
          <td class="text-right">Abstand:</td>
          <td class="px-1 text-right grey lighten-1">
            {{ stock.percentToPriceTarget | percent }}
          </td>
        </tr>
        <tr>
          <td class="text-right">Kursdatum:</td>
          <td colspan="3" class="px-1 text-right grey lighten-1">
            {{ stock.date | date }}
          </td>
        </tr>
      </table>
    </v-card-text>
    <v-card-actions class="my-n2">
      <v-container>
        <v-row>
          <v-btn
            text
            x-small
            :href="
              'https://www.onvista.de/aktien/snapshot.html?ISIN=' + stock.isin
            "
            target="_blank"
            >Onvista</v-btn
          >
          <v-btn
            text
            x-small
            :href="
              'https://www.finanzen.net/suchergebnis.asp?frmAktiensucheTextfeld=' +
              stock.isin
            "
            target="_blank"
            >Finanzen</v-btn
          >
          <v-btn
            text
            x-small
            :href="'https://www.morningstar.com/search?query=' + stock.name"
            target="_blank"
            >Morningstar</v-btn
          >
          <v-btn
            text
            x-small
            :href="'https://www.fool.de/?s=' + stock.name"
            target="_blank"
            >Fool</v-btn
          >
          <v-btn
            text
            x-small
            :href="'https://de.finance.yahoo.com/quote/' + stock.name"
            target="_blank"
            >Yahoo</v-btn
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom open-delay="500">
            <template #activator="{ on }">
              <v-btn
                icon
                x-small
                :loading="refreshPrice"
                :disabled="loading"
                v-on="on"
                @click="updatePrice"
              >
                <v-icon
                  v-bind:class="{ customLoader: loading }"
                  small
                  >mdi-cached</v-icon
                >
              </v-btn>
            </template>
            <span>Kurs aktualisieren</span>
          </v-tooltip>
          <!-- Falls wir noch kein Pricetarget haben, zeigen wir einen Button zum Anlegen an. -->
          <template v-if="!priceTargetAvailable">
            <target-price-dialog
              :priceTarget="priceTarget"
              :priceTargetAvailable="priceTargetAvailable"
              v-on:target-saved="targetSaved"
              v-on:target-deleted="targetDeleted"
            ></target-price-dialog>
          </template>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
// import * as format from "@/services/formatter.js";
import { log, isToday, logJson } from "@/services/utils.js";
// import StockpriceCard from "@/components/StockpriceCard.vue";
// import TargetPriceDialog from "@/components/TargetPriceDialog.vue";
import TargetPriceDialog from "./TargetPriceDialog.vue";
import ApiService from "@/services/api.service";
export default {
  components: { TargetPriceDialog },
  name: "StockpriceCard",
  // props: ["stockData"],
  props: {
    stock: {
      isin: { required: true, type: String },
      dataProvider: { required: true, type: String },
      price: { required: true, type: Number },
      date: { required: true, type: Date },
      name: { required: true, type: String },
      changeInPercent: { required: true, type: Number },
      changeInCurrency: { required: true, type: Number },
      changeToMaxInPercent: { required: true, type: Number },
      changeToMinInPercent: { required: true, type: Number },
    },
  },
  // Make properties (parameters, that are passed into the component) available as local model (properties shouldn't be used for that)
  // This is a necessary pattern, when the data is changed (which is not the case here)
  data() {
    return {
      priceTarget: {
        price:
          this.stock.priceTarget == null
            ? this.stock.price
            : this.stock.priceTarget.price,
        isin: this.stock.isin,
        name: this.stock.name,
        action:
          this.stock.priceTarget == null
            ? "BUY"
            : this.stock.priceTarget.action,
        private: true,
        notes:
          this.stock.priceTarget == null
            ? "Meine Notizen zum Preisziel"
            : this.stock.priceTarget.notes,
      },
      priceTargetAvailable: this.stock.priceTarget != null,
      loader: null,
      refreshPrice: false,
      loading: false,
    };
  },
  computed: {
    computedColor: function () {
      log("in computedColor " + this.stock.sortColumn);
      switch (this.stock.sortColumn) {
        case "priceTarget":
          return this.priceTargetColor();
        case "changeToMax":
          return this.changeToMaxColor();
        case "price":
          return this.priceColor();
        case "date":
          return this.dateColor();
        case "change":
        default:
          return this.changeColor();
      }
    },
  },
  // filters: {
  //   currency: function (value) {
  //     return format.toCurrency(value);
  //   },
  //   date: function (value) {
  //     return format.toDateTime(value);
  //   },
  //   percent: function (value) {
  //     return format.toPercent(value);
  //   },
  // },
  methods: {
    changeColor: function () {
      // log("in changeColor");
      let change = this.stock.changeInPercent;
      if (change >= 0.1) {
        return "green darken-1";
      } else if (change < 0.1 && change >= 0.08) {
        return "green lighten-1";
      } else if (change < 0.08 && change >= 0.06) {
        return "green lighten-2";
      } else if (change < 0.06 && change >= 0.04) {
        return "green lighten-3";
      } else if (change < 0.04 && change >= 0.02) {
        return "green lighten-4";
      } else if (change < 0.02 && change >= 0.0) {
        return "green lighten-5";
      } else if (change >= -0.02 && change < 0) {
        return "red lighten-5";
      } else if (change >= -0.04 && change < -0.02) {
        return "red lighten-4";
      } else if (change >= -0.06 && change < -0.04) {
        return "red lighten-3";
      } else if (change >= -0.08 && change < -0.06) {
        return "red lighten-2";
      } else if (change >= -0.1 && change < -0.08) {
        return "red lighten-1";
      } else {
        return "red darken-1";
      }
    },
    priceTargetColor: function () {
      log("in priceTargetColor");
      let change = this.stock.percentToPriceTarget;
      if (change >= -0.05 && change < 0) {
        return "green lighten-5";
      } else if (change >= -0.1 && change < -0.05) {
        return "green lighten-4";
      } else if (change >= -0.2 && change < -0.1) {
        return "green lighten-3";
      } else if (change >= -0.3 && change < -0.2) {
        return "green lighten-2";
      } else if (change >= -0.4 && change < -0.3) {
        return "green lighten-1";
      } else if (change < -0.4) {
        return "green darken-1";
      } else if (change > 0 && change <= 0.1) {
        return "red lighten-5";
      } else if (change > 0.1 && change <= 0.2) {
        return "red lighten-4";
      } else if (change > 0.2 && change <= 0.3) {
        return "red lighten-3";
      } else if (change > 0.3 && change <= 0.4) {
        return "red lighten-2";
      } else if (change > 0.4) {
        return "red lighten-1";
      } else {
        return "grey lighten-2";
      }
    },
    changeToMaxColor: function () {
      // log("in changeToMaxColor");
      let change = this.stock.changeToMaxInPercent;
      if (change >= -0.1) {
        return "green darken-1";
      } else if (change < -0.1 && change >= -0.2) {
        return "green lighten-1";
      } else if (change < -0.2 && change >= -0.3) {
        return "green lighten-2";
      } else if (change < -0.3 && change >= -0.4) {
        return "green lighten-3";
      } else if (change < -0.4 && change >= -0.5) {
        return "green lighten-4";
      } else if (change < -0.5 && change >= -0.6) {
        return "green lighten-5";
      } else if (change < -0.6 && change >= -0.7) {
        return "red lighten-5";
      } else if (change < -0.7 && change >= -0.8) {
        return "red lighten-4";
      } else if (change < -0.8 && change >= -0.9) {
        return "red lighten-3";
      } else if (change < -0.9 && change >= -1) {
        return "red lighten-2";
        // } else if (change >= -0.1 && change < -0.08) {
        //   return "red lighten-1";
      } else {
        return "red darken-1";
      }
    },
    priceColor: function () {
      // log("in changePrice");
      let change = this.stock.price;
      if (change >= 1500) {
        return "green darken-1";
      } else if (change < 1500 && change >= 1000) {
        return "green lighten-1";
      } else if (change < 1000 && change >= 100) {
        return "green lighten-2";
      } else if (change < 100 && change >= 50) {
        return "green lighten-3";
      } else if (change < 50 && change >= 10) {
        return "green lighten-4";
      } else if (change < 10 && change >= 5) {
        return "green lighten-5";
      } else if (change < 5 && change >= 1) {
        return "red lighten-5";
      } else if (change < 1) {
        return "red lighten-4";
      }
    },
    dateColor: function () {
      log("in date");
      let date = new Date(this.stock.date);
      if (isToday(date)) {
        return "blue lighten-4";
      } else {
        return "red lighten-4";
      }
    },
    targetSaved: function () {
      log("priceTarget was saved");
      // logJson(this.priceTarget);
      this.initPriceTarget();
    },
    targetDeleted: function () {
      this.stock.priceTarget = null;
      this.priceTargetAvailable = false;
    },
    initPriceTarget: function () {
      const resource = "/api/stocks/dashboard/stockmodel/" + this.stock.isin;
      ApiService.get(resource)
        .then((response) => {
          logJson(response);
          Object.assign(this.stock, response.data);
          this.priceTargetAvailable = true;
        })
        .finally(() => {});
    },
    updatePrice() {
      this.loading = true;
      const path = "/api/stocks/dashboard/update/" + this.stock.isin;
      // log(path);
      const stockmodelUpdateRequest = {
        method: "get",
        url: path,
      };
      ApiService.customRequest(stockmodelUpdateRequest)
        .then((response) => {
          log(response);
          var json = response.data;
          this.stock.price = json.price;
          this.stock.date = json.date;
          this.stock.changeInPercent = json.changeInPercent;
          this.stock.changeInCurrency = json.changeInCurrency;
          this.stock.changeToMaxInPercent = json.changeToMaxInPercent;
          this.stock.changeToMinInPercent = json.changeToMinInPercent;
        })
        .finally(() => {
          this.loading = false;
          // setTimeout(() => {
          //   this.loading = false;
          // }, 5000);
        });
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      // setTimeout(() => (this[l] = false), 3000);
      this[l] = false;
      this.loader = null;
    },
  },
};
</script>

<style>
</style>
