<template>
  <v-data-table :headers="headers" :items="users" sort-by="calories" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>User List</v-toolbar-title>
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New User</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.username" label="Username"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.password" label="Password"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-text-field v-model="editedItem.role" label="Role"></v-text-field> -->
                    <v-select v-model="editedItem.role" :items="roles" label="Role"></v-select>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.profile" label="Profile"></v-text-field>
                  </v-col>-->
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Refresh</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import ApiService from "@/services/api.service";
import { log } from "@/services/utils.js";

export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "ID",
        align: "start",
        // sortable: false,
        value: "id",
      },
      { text: "Username", value: "username" },
      // { text: "Password", value: "password" },
      { text: "Role", value: "role" },
      { text: "Profile", value: "profile" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    roles: ["USER", "ADMIN", "INVESTOR"],
    users: [],
    editedIndex: -1,
    editedItem: {
      username: "",
      password: "",
      role: "",
      // profile: ""
    },
    // defaultItem: {
    //   username: "Neuer User",
    //   password: "Passwort",
    //   role: "USER",
    //   // profile: ""
    // },
    defaultItem: {
      username: "",
      password: "",
      role: "USER",
      // profile: ""
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    refreshUsersFromServer() {
      ApiService.get("/api/stocks/users").then((response) => {
        this.users = response.data;
      });
    },

    initialize() {
      this.refreshUsersFromServer();
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    editItem(user) {
      log("Editing ");
      log(user);
      this.editedIndex = this.users.indexOf(user);
      this.editedItem = Object.assign({}, user);
      this.dialog = true;
    },

    deleteItem(user) {
      const index = this.users.indexOf(user);
      log("Deleting user with id " + user.id);
      // confirm("Are you sure you want to delete this user?") &&
      ApiService.delete("users/" + user.id).then((response) => {
        log(response);
        this.users.splice(index, 1);
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // log("editedIndex am Anfang" + this.editedIndex);
      if (this.editedIndex > -1) {
        log("editedIndex in der Methode" + this.editedIndex);
        const requestData = {
          method: "PUT",
          url: "/api/stocks/users",
          data: this.editedItem,
        };
        ApiService.customRequest(requestData)
          // .then(response => {
          //   log(response);
          //   log(response.data);
          //   log("editedIndex in nach der Response" + this.editedIndex);
          //   // this.users[this.editedIndex] = response.data;
          // })
          .then(() => {
            // log(this.users);
            // log(this.editedIndex);
            // log(this.users[this.editedIndex]);
            // log(this.editedItem);
            Object.assign(this.users[this.editedIndex], this.editedItem);
          })
          .finally(() => {
            this.close();
          });
      } else {
        const requestData = {
          method: "post",
          url: "/api/stocks/users",
          data: this.editedItem,
        };
        ApiService.customRequest(requestData)
          .then((response) => {
            log(response);
            this.users.push(response.data);
          })
          .finally(() => {
            this.close();
          });
      }
    },
  },
};
</script>