import Vue from "vue";
import * as format from "@/services/formatter.js";

Vue.filter("first4Chars", str => str.substring(0, 4))
Vue.filter("last4Chars", str => str.substring(str.length - 4))
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('currency', function (value) {
  return format.toCurrency(value);
})
Vue.filter('amount', function (value) {
  return format.toAmount(value);
})
Vue.filter('date', function (value) {
  return format.toDateTime(value);
})
Vue.filter('percent', function (value) {
  return format.toPercent(value);
})


