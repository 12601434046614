/* eslint-disable no-console */
import Vue from "vue";
// import App from "./App.vue";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import ApiService from "@/services/api.service";
// import TokenService from "@/services/token.service";
import store from "@/store";
// import TokenService from "./services/token.service";
import "./filters";
// import "./services/thommis.js";
// import "./services/formatter.js";
import "@/assets/css/main.css";

Vue.config.productionTip = false;
ApiService.init(process.env.VUE_API_SERVER_URL);

// Login, falls ein Token da ist

new Vue({
  vuetify,
  router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
