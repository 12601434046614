import { log, decodeJwt } from "@/services/utils";
import { user, admin, investor } from "@/utils/roles";
const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to sessionStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return sessionStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    sessionStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    sessionStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },
  getDecodedToken() {
    return decodeJwt(this.getToken());
  },
  removeRefreshToken() {
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  },
  hasValidToken() {
    false;
  },
  hasAdminRole() {
    return this.hasRole(admin);
  },
  hasUserRole() {
    return this.hasRole(user);
  },
  hasInvestorRole() {
    return this.hasRole(investor);
  },
  hasRole(role) {
    log("checking for role " + role);
    if (!!this.decodedToken() && !!this.decodedToken().authorities) {
      if (this.decodedToken().authorities.some((el) => el.authority === role)) {
        return true;
      }
    }
    return false;
  },
  // currently returning true if we have a token at all
  // At a later stage we might extend that to check if the token is valid and not expired
  isLoggedIn() {
    return !!this.getToken();
  },
  cleanup() {
    this.removeToken();
    this.removeRefreshToken();
  },
  decodedToken() {
    log("decodedToken()");
    if (this.getToken()) {
      let decodedToken = decodeJwt(this.getToken());
      // log(decodedToken);
      return decodedToken;
    } else {
      return null;
    }
  },
};

export default TokenService;
export { TokenService };
// export default TokenService;
