<!--
  General purpose Alert Component which is implemented as Snackbar, that disappears after 5s.
  Usasage:
  1) Add the following import statement:

    import FadeoutAlert from "@/components/FadeoutAlert";

  2) Add the following to the export section of the component in use:

    methods: {
      showAlert(messages, type) {
        this.alert.messages = messages;
        this.alert.type = type;
        this.alert.show = !this.alert.show;
      },
    },
    components: {
      FadeoutAlert,
    },


  2) Add the following fields to the data section:
    
  data: () => ({
    alert: {
        show: true,
        messages: ["Hallo Welt!"],
        type: "success",
      },
    }
  }

  3) Add the following to the e.g. the top of the template
    
    <fadeout-alert :show="alert.show" :type="alert.type">
      <div v-for="item in alert.messages" :key="item">
        {{ item }}
      </div>
    </fadeout-alert>
  





-->
<template>
  <div>
    <v-snackbar v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners" v-model="snackbar">
      <template>
        <div class="d-flex">
          <div>
          <v-icon
            v-if="type === 'success'"
            color="success"
            class="pr-4 align-self-start"
            >mdi-check</v-icon
          >
          <v-icon
            v-if="type === 'error'"
            color="error"
            class="pr-4 align-self-start"
            >mdi-alert-circle-outline</v-icon
          >

          </div>
          <div><slot></slot></div>
        </div>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    show: { required: false, type: Boolean, default: false },
    type: { required: false, type: String, default: "success" },
    messages: { required: false, type: Array },
  },
  computed: {
    commonAttrs() {
      return {
        // timeout:5000,
      };
    },
  },
  data: function () {
    return {snackbar:false};
  },
  watch: {
    // Über eine Wertänderung der show-Property kann man die Snackbar von einer Parent-Komponente aus schließen.
    show(newVal, oldVal) {
      this.snackbar=true;
    },
  },
  methods: {
  },
};
</script> 
