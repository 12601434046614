<template>
  <div>
    <fadeout-alert :show="alert.show" :type="alert.type" timeout="5000">
      <div v-for="item in alert.messages" :key="item">
        {{ item }}
      </div>
    </fadeout-alert>
    <!-- items-per-page = -1 for default="all" -->
    <v-data-table
      :headers="headers"
      :items="wertpapiere"
      :search="search"
      :items-per-page="-1"
      sort-by="wertpapierbezeichnung"
      class="elevation-1"
    >
      <template v-slot:item.holeKurs="{ item }">
        <v-icon v-if="item.holeKurs">mdi-check</v-icon>
        <v-icon v-else>mdi-minus</v-icon>
      </template>
      <template v-slot:item.enabled="{ item }">
        <v-icon v-if="item.enabled">mdi-check</v-icon>
        <v-icon v-else>mdi-minus</v-icon>
        <!-- <v-simple-checkbox v-model="item.enabled" disabled></v-simple-checkbox> -->
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Wertpapiere in Datenbank</v-toolbar-title>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
                >Wertpapier anlegen</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.isin"
                        label="ISIN"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.wertpapierbezeichnung"
                        label="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-combobox
                        v-model="editedItem.country"
                        label="Region"
                        :items="regions"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-combobox
                        v-model="editedItem.industry"
                        label="Branche"
                        :items="industries"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.yahooMapping"
                        label="Yahoo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="editedItem.dataProvider"
                        :items="provider"
                        label="Kursquelle"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="editedItem.assetType"
                        :items="assetTypes"
                        label="Asset Typ"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.ticker"
                        label="Ticker"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-checkbox
                        v-model="editedItem.holeKurs"
                        label="Kursabfrage"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <!-- <v-checkbox v-model="flag" :label="`Checkbox 1: ${flag.toString()}`"></v-checkbox> -->
                      <v-checkbox
                        v-model="editedItem.enabled"
                        label="Depotberechnung"
                      ></v-checkbox>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.profile" label="Profile"></v-text-field>
                  </v-col>-->
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="updateHistoricPrices"
                  :disabled="loading"
                  >Historic</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="updatePrice"
                  :disabled="loading"
                >
                  Update</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="updatePrices"
                  :disabled="loading"
                >
                  Kurse</v-btn
                >
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading"
                ></v-progress-circular>
              </v-card-actions>
              <v-card-text>
                <v-simple-table v-if="prices" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Provider</th>
                        <th class="text-left">Kurs</th>
                        <th class="text-left">Datum</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(stockprice, name) in prices"
                        v-bind:key="name"
                      >
                        <td>{{ name }}</td>
                        <td v-if="stockprice !== null">
                          {{ stockprice.price | amount }}
                        </td>
                        <td v-else>null</td>
                        <td v-if="stockprice !== null">
                          {{ stockprice.dateInMillis | date }}
                        </td>
                        <td v-else>null</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar flat dense>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refresh</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import FadeoutAlert from "@/components/FadeoutAlert";
import { log } from "@/services/utils.js";

export default {
  data: () => ({
    dialog: false,
    search: "",
    headers: [
      {
        text: "ISIN",
        align: "start",
        value: "isin",
      },
      { text: "Name", value: "wertpapierbezeichnung" },
      { text: "Branche", groupable: true, value: "industry" },
      { text: "Region", value: "country" },
      { text: "Kursquelle", value: "dataProvider" },
      { text: "Yahoo", value: "yahooMapping" },
      { text: "Ticker", value: "ticker" },
      { text: "Typ", value: "assetType" },
      { text: "Kursabfrage", value: "holeKurs", filterable: false },
      { text: "Depotberechnung", value: "enabled", filterable: false },
      { text: "Actions", value: "actions", sortable: false, filterable: false },
    ],
    // filled from the backend
    regions: [],
    // filled from the backend
    industries: [],
    provider: [
      "FINANZEN",
      "YAHOO",
      "ALPHAVANTAGE",
      "IEX",
      "TRADEGATE",
      "ONVISTA",
    ],
    assetTypes: [
      "AKTIE",
      "FONDS",
    ],
    prices: "",
    flag: true,
    loading: false,
    wertpapiere: [],
    editedIndex: -1,
    editedItem: {
      isin: "",
      wertpapierbezeichnung: "",
      industry: "",
      country: "",
      yahooMapping: "",
      ticker: "",
      provider: "",
      assetType: "",
      enabled: "",
      holeKurs: "",
    },
    defaultItem: {
      isin: "",
      wertpapierbezeichnung: "",
      industry: "",
      country: "",
      yahooMapping: "",
      ticker: "",
      provider: "",
      assetType: "",
      enabled: true,
      holeKurs: true,
    },
    alert: {
      show: false,
      messages: [],
      type: "success",
    },
  }),
  components: {
    FadeoutAlert,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Neues Wertpapier anlegen"
        : "Wertpapier ändern";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    refreshFromServer() {
      ApiService.get("/api/stocks/securities").then((response) => {
        this.wertpapiere = response.data;
        this.showAlert(
          [
            "" +
              this.wertpapiere.length +
              " Wertpapiere aus der Datenbank geladen",
          ],
          "success"
        );
      });
      ApiService.get("/api/stocks/securities/regions").then((response) => {
        this.regions = response.data;
      });
      ApiService.get("/api/stocks/securities/industries").then((response) => {
        this.industries = response.data;
      });
    },

    initialize() {
      this.refreshFromServer();
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    editItem(item) {
      log("Editing ");
      log(item);
      this.editedIndex = this.wertpapiere.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.wertpapiere.indexOf(item);
      log("Deleting user with id " + item.isin);
      confirm(
        "Möchten Sie " +
          item.wertpapierbezeichnung +
          " (" +
          item.isin +
          ") wirklich dauerhaft aus der Wertpapierliste löschen?"
      ) &&
        ApiService.delete("/api/stocks/securities/" + item.isin)
          .then((response) => {
            log(response);
            this.wertpapiere.splice(index, 1);
            this.showAlert(
              ["" + item.wertpapierbezeichnung + " erfolgreich gelöscht"],
              "success"
            );
          })
          .catch((e) => {
            let messages = [];
            messages.push(
              "Fehler beim Löschen eines Wertpapiers " +
                item.wertpapierbezeichnung
            );
            messages.push(e.message);
            this.showAlert(messages, "error");
          });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // log("editedIndex am Anfang" + this.editedIndex);
      // Editieren
      if (this.editedIndex > -1) {
        log("editedIndex in der Methode" + this.editedIndex);
        const requestData = {
          method: "PUT",
          url: "/api/stocks/securities",
          data: this.editedItem,
        };
        ApiService.customRequest(requestData)
          // .then(response => {
          //   log(response);
          //   log(response.data);
          //   log("editedIndex in nach der Response" + this.editedIndex);
          //   // this.users[this.editedIndex] = response.data;
          // })
          .then((response) => {
            this.showAlert(
              [
                "Wertpapier " +
                  this.editItem.wertpapierbezeichnung +
                  " geändert.",
              ],
              "success"
            );
            Object.assign(this.wertpapiere[this.editedIndex], this.editedItem);
          })
          .catch((e) => {
            let messages = [];
            messages.push(
              "Fehler beim Ändern eines Wertpapiers " +
                this.editItem.wertpapierbezeichnung
            );
            messages.push(e.message);
            this.showAlert(messages, "error");
          })
          .finally(() => {
            this.close();
          });
      } else {
        // Neu anlegen
        log("Edited item vor dem Speichern");
        log(this.editedItem);
        const requestData = {
          method: "post",
          url: "/api/stocks/securities",
          data: this.editedItem,
        };
        ApiService.customRequest(requestData)
          .then((response) => {
            log(response);
            this.wertpapiere.push(response.data);
            this.showAlert(
              [
                "Wertpapier " +
                  response.data.wertpapierbezeichnung +
                  " gespeichert.",
              ],
              "success"
            );
          })
          .catch((e) => {
            let messages = [];
            messages.push(
              "Fehler beim Speichern des Wertpapiers " +
                this.editItem.wertpapierbezeichnung
            );
            messages.push(e.message);
            this.showAlert(messages, "error");
          })

          .finally(() => {
            log("Edited item nach dem Speichern");
            log(this.editedItem);
            this.close();
          });
      }
    },
    updatePrice() {
      log("Persisted price update for the current itme");
      log(this.editedItem);
      const requestData = {
        method: "post",
        url: "/api/stocks/stockprices/update",
        data: this.editedItem,
      };
      this.loading = true;

      ApiService.customRequest(requestData)
        .then((response) => {
          log(response);
          const message =
            "Kurs aktualisiert: " +
            response.data.price +
            " (" +
            response.data.date +
            ")";
          let messages = [];
          messages.push(message);
          this.showAlert(messages, "success");
        })
        .catch((e) => {
          let messages = [];
          messages.push("Fehler beim Aktualisieren des Wertpapierkurses: ");
          messages.push(e.message);
          this.showAlert(messages, "error");
        })
        .finally(() => {
          log("Nach dem Kursupdate");
          this.loading = false;
        });
    },
    updateHistoricPrices() {
      log("Getting historic prices from current Wertpapiert");
      log(this.editedItem);
      const requestData = {
        method: "post",
        url: "/api/stocks/stockprices/updateHistoric",
        data: this.editedItem,
      };
      this.loading = true;

      ApiService.customRequest(requestData)
        .then((response) => {
          log(response);
          // this.wertpapiere.push(response.data);
          let messages = ["" + response.data + " Kurse aktualisiert."];
          this.showAlert(messages, "success");
        })
        .catch((e) => {
          let messages = [];
          messages.push("Fehler beim Aktualisieren historischer Kurse: ");
          messages.push(e.message);
          this.showAlert(messages, "error");
        })
        .finally(() => {
          log("Nach dem Kursupdate");
          this.loading = false;
        });
    },
    updatePrices() {
      // log("Edited item vor dem Speichern");
      // log(this.editedItem);
      const requestData = {
        method: "post",
        url: "/api/stocks/stockprices/allproviders",
        data: this.editedItem,
      };
      this.loading = true;
      ApiService.customRequest(requestData)
        .then((response) => {
          var json = response.data;
          this.prices = json;
          this.showAlert([" Kurse aktualisiert."], "success");
          log(response);
        })
        .catch((e) => {
          let messages = [];
          messages.push("Fehler beim Aktualisieren der Kurse: ");
          messages.push(e.message);
          this.showAlert(messages, "error");
        })
        .finally(() => {
          this.loading = false;
          // log("Edited item nach dem Speichern");
          // log(this.editedItem);
          // this.close();
        });
    },
    logItem() {
      log(this.editedItem);
    },
    updateCountry(currentValue) {
      log(currentValue);
      log(this.editItem.country);
      this.editItem.country = currentValue;
      log(this.editItem.country);
    },
    showAlert(messages, type) {
      this.alert.messages = messages;
      this.alert.type = type;
      this.alert.show = !this.alert.show;
    },
  },
};
</script>
