<!-- Component to edit and maintain the stocks on a watchlist (via two tables) -->
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="red lighten-5">
        <h3>Available Securities ({{ securities.length }})</h3>
        <v-sheet
          class="red lighten-4 ma-1 rounded"
          v-for="wertpapier in securities"
          :key="wertpapier.isin"
          ><v-row no-gutters align="center" class="d-flex">
            <div class="pl-2">
              {{ wertpapier.wertpapierbezeichnung }}
            </div>
            <v-btn icon @click="plus($event, wertpapier)" class="ml-auto">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="6" align="stretch" class="green lighten-5">
        <h3>Your Watchlist ({{ watchlist.length }})</h3>
        <v-sheet
          class="green lighten-4 ma-1 rounded"
          v-for="wertpapier in watchlist"
          :key="wertpapier.isin"
          ><v-row no-gutters align="center" class="d-flex">
            <div class="pl-2">
              {{ wertpapier.wertpapierbezeichnung }}
            </div>
            <v-btn icon @click="minus($event, wertpapier)" class="ml-auto">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApiService from "@/services/api.service";
import { log } from "../services/utils.js";

export default {
  name: "watchlist-editor2",
  display: "Watchlist Editor 2",
  order: 1,
  components: {},
  data() {
    return {
      securities: [],
      watchlist: [],
    };
  },
  methods: {
    plus: function (evt, wertpapier) {
      log("Wertpapier added" + wertpapier.wertpapierbezeichnung);
      log(evt);
      this.addWertpapierToBackendWatchlist(wertpapier);
    },
    minus: function (evt, wertpapier) {
      log("Wertpapier removed" + wertpapier.wertpapierbezeichnung);
      log(evt);
      this.removeWertpapierFromBackendWatchlist(wertpapier);
    },
    add: function (evt) {
      log("Wertpapier added");
      log(evt);
      this.addWertpapierToBackendWatchlist(this.watchlist[evt.newIndex]);
    },
    remove: function (evt) {
      log("Wertpapier removed");
      log(evt);
      this.removeWertpapierFromBackendWatchlist(this.securities[evt.newIndex]);
    },
    loadRemainingSecuritiesFromBackend() {
      ApiService.get("/api/stocks/watchlist/remaining").then((response) => {
        this.securities = response.data.sort(this.alphaSort);
      });
    },
    loadWatchlistFromBackend() {
      ApiService.get("/api/stocks/watchlist").then((response) => {
        this.watchlist = response.data.stocks.sort(this.alphaSort);
      });
    },
    // alphabetical sorting of a wertpapier list
    alphaSort(a, b) {
      var nameA = a.wertpapierbezeichnung.toUpperCase(); // ignore upper and lowercase
      var nameB = b.wertpapierbezeichnung.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    },
    addWertpapierToBackendWatchlist(wertpapier) {
      ApiService.post("/api/stocks/watchlist/add", wertpapier).then(
        (response) => {
          log(response.data);
          this.updateLists();
        }
      );
    },
    removeWertpapierFromBackendWatchlist(wertpapier) {
      ApiService.post("/api/stocks/watchlist/remove", wertpapier).then(
        (response) => {
          log(response.data);
          this.updateLists();
        }
      );
    },
    updateLists() {
      this.loadWatchlistFromBackend();
      this.loadRemainingSecuritiesFromBackend();
    },
  },
  created() {
    this.loadWatchlistFromBackend();
    this.loadRemainingSecuritiesFromBackend();
  },
};
</script>
